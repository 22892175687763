:root {
  /* Here you can override some variable from react-toastify: https://fkhadra.github.io/react-toastify/how-to-style */
  --toastify-toast-width: 400px;
}

.animate__superfast {
  --animate-duration: 0.15s;
}

/* Toast shape */
.toast {
  border-radius: 0.5em;
  padding: 0;
  min-height: 2.5em;
}

.toast-body {
  padding: 0;
  margin: 0;
  align-items: normal;
}

/* Icon container */
.toast-body div:has(svg) {
  padding-left: 0.5em;
  padding-right: 0.5em;
  animation-duration: unset;
  margin: 0;
  width: fit-content;
  background-color: #285aff;
  color: white;
}

/* Toast icon */
.toast-body svg {
  margin: auto;
}

/* Text container  */
.toast-body div:has(.toast-text) {
  background-color: #374649;
  display: flex;
}

/* Toast text */
.toast-body .toast-text {
  margin: auto;
  color: white;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5em 1em;
}

/* Style for notification type, the default one is ".toast-body div:has(svg)" */
.toast-error div:has(svg) {
  background-color: #ee0000;
  color: white;
}

.toast-warning div:has(svg) {
  background-color: #ffe74f;
  color: #374649;
}

.toast-success div:has(svg) {
  background-color: #72eac5;
  color: #374649;
}
